<template>
    <list-title-bar :titleArray="titleArray"></list-title-bar>
  </template>
  
  <script>
  import ListTitleBar from "@c/operation-log/common/paging-list/list-title-bar.vue";
  
  export default {
    components: { ListTitleBar },
  
    computed: {
      titleArray() {
        return [
          { name: "机构ID", percent: "8%" },
          { name: "机构名称", percent: "30%" },
          { name: "创建时间", percent: "20%" },
          { name: "机构管理员/机构操作员", percent: "18%" },
          { name: "状态", percent: "13%" },
          { name: "操作", percent: "12%" },
        ];
      },
    },
  };
  </script>